<template>
  <Layout>
    <div class="content">
      <el-form
        ref="form"
        v-loading="fetchLoading"
        :model="formData"
        :rules="rules"
        size="small"
        label-width="120px"
        label-position="left"
      >
        <h4 class="title">
          基本信息
        </h4>
        <el-form-item
          label="兑换活动名称"
          prop="activityName"
        >
          <el-input
            v-model="formData.activityName"
            placeholder="单行输入"
            maxlength="20"
            show-word-limit
          />
        </el-form-item>
        <el-form-item
          label="生成方式"
          prop="generateMode"
        >
          <el-radio
            v-model="formData.generateMode"
            :label="1"
          >
            自动生成
          </el-radio>
          <!-- <el-radio
            v-model="formData.generateMode"
            :label="2"
          >
            导入
          </el-radio> -->
        </el-form-item>
        <el-form-item
          label="发放总量"
          prop="codeNum"
        >
          <el-input-number
            v-model="formData.codeNum"
            :min="1"
            label="单行输入"
          />
        </el-form-item>
        <el-form-item
          label="渠道类型"
          prop="channelId"
        >
          <el-select
            v-model="formData.channelId"
            clearable
          >
            <el-option
              v-for="(item, index) in promotionChannelList"
              :key="index"
              :label="item.channelName"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          label="渠道名称"
          prop="channelName"
        >
          <el-input
            v-model="formData.channelName"
            placeholder="单行输入"
          />
        </el-form-item>
        <el-form-item
          label="适用商品"
          prop="goodsType"
        >
          <el-radio-group v-model="formData.goodsType">
            <el-radio :label="1">
              VIP
            </el-radio>
            <el-radio :label="2">
              金币
            </el-radio>
            <el-radio :label="3">
              时长卡(秒)
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          v-if="formData.goodsType === 1"
          prop="goodsId"
        >
          <el-select v-model="formData.goodsId">
            <el-option
              v-for="(item, index) in vipTypeList"
              :key="index"
              :label="item.name"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="formData.goodsType === 2 || formData.goodsType === 3"
          prop="goldNum"
        >
          <el-input-number
            v-model="formData.goldNum"
            :min="1"
            label="单行输入"
          />
        </el-form-item>
        <el-form-item
          label="可用时间"
          prop="dateRange"
        >
          <el-date-picker
            v-model="formData.dateRange"
            type="datetimerange"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          />
        </el-form-item>
        <h4 class="title">
          领取&使用规则
        </h4>
        <el-form-item
          label="每人限领次数"
          prop="limitType"
        >
          <el-radio-group v-model="formData.limitType">
            <el-radio :label="1">
              不限制领取次数
            </el-radio>
            <el-radio :label="2">
              限领
              <el-input-number
                v-model="formData.receiveCount"
                :min="1"
                label="单行输入"
              />
              次
            </el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div class="ctrl-area">
        <el-button
          :loading="updateLoading"
          type="primary"
          @click="handleSubmit"
        >
          保存
        </el-button>
        <el-button @click="handleCancel">
          取消
        </el-button>
      </div>
    </div>
  </Layout>
</template>

<script>
import {
  promotionStateList,
  promotionStateMap,
  triggerModeList,
  triggerModeMap
} from '@/utils/selectOptions.js'
import {
  getAllChannelType,
  createRedeemActivity,
  updateRedeemActivity,
  getRedeemActivityDetail,
  couponCodeGetGoods
} from '@/api/CouponsAndRedeemCode.js'
import {
  APPID,
  GOODS_TYPE,
  CHANNEL,
  EXPERIMENT_GROUP
} from '@/enum'

import { cloneDeep } from 'lodash'

export default {
  name: 'CreateOrEditRedeemCode',
  data () {
    const increaseCheck = (rule, value, callback) => {
      if (value >= this.oldCodeNum) {
        callback()
      } else {
        callback(new Error('发放总量不得低于原来的数量'))
      }
    }

    const dateRangeValidator = (rule, value, callback) => {
      if (!value || !value.length || !value[0]) {
        callback(new Error('时间范围必选'))
      } else {
        callback()
      }
    }

    const limitTypeValidator = (rule, value, callback) => {
      if (value === 1) {
        callback()
      } else if (!this.formData.receiveCount) {
        callback(new Error('请填写限领次数'))
      } else {
        callback()
      }
    }
    return {
      promotionStateList,
      promotionStateMap,
      promotionChannelList: [],
      triggerModeList,
      triggerModeMap,
      isRedeemCodeEdit: false,
      fetchLoading: false,
      formData: {},
      oldCodeNum: 0,
      rules: {
        activityName: [{ required: true, message: '兑换活动名称必填' }],
        generateMode: [{ required: true, message: '生成方式必填' }],
        codeNum: [{ required: true, message: '发放总量必填', validator: increaseCheck }],
        channelId: [{ required: true, message: '渠道类型必填' }],
        channelName: [{ required: true, message: '渠道名称必填' }],
        goodsType: [{ required: true, message: '商品类型必选' }],
        goodsId: [{ required: true, message: 'VIP必选' }],
        goldNum: [{ required: true, message: '金币数必填' }],
        dateRange: [{ required: true, validator: dateRangeValidator }],
        limitType: [
          { required: true, message: '必须设置限领规则' },
          { validator: limitTypeValidator }
        ]
      },
      updateLoading: false,
      vipTypeList: [],
      vipTypeMap: {}
    }
  },
  created () {
    this.getAllChannelType()
    this.queryVipList()
    this.isRedeemCodeEdit = this.$route.name === 'RedeemCodeEdit'
    if (this.isRedeemCodeEdit) {
      this.handleQueryFormInfo()
    }
  },
  methods: {
    queryVipList () {
      couponCodeGetGoods({
        appId: APPID.Netboom,
        channel: CHANNEL.Android,
        experimentGroup: EXPERIMENT_GROUP.BASE_GROUP,
        goodsType: GOODS_TYPE.VIP
      }).then(res => {
        if (res.code === 200) {
          const list = res.data.list
          list.forEach((item, index) => {
            this.vipTypeList[index] = {
              name: item.name,
              value: item.goodsId
            }
          })
          const vipTypeMap = list.reduce((result, item) => {
            result[item.goodsId] = item
            return result
          }, {})
          this.$set(this, 'vipTypeMap', vipTypeMap)
        }
      })
    },
    getAllChannelType () {
      getAllChannelType({ activityType: 1 })
        .then(res => {
          if (res.code === 200) {
            this.promotionChannelList = res.data.list || []
          }
        })
    },
    handleQueryFormInfo () {
      const id = Number(this.$route.query.id)
      this.fetchLoading = true
      getRedeemActivityDetail({ id })
        .then(res => {
          if (res.code === 200) {
            this.formData = res.data
            this.$set(this.formData, 'limitType', this.formData.receiveCount === -1 ? 1 : 2)
            this.$set(this.formData, 'dateRange', [this.formData.startUsedTime, this.formData.endUsedTime])
          }
        })
        .finally(() => {
          this.fetchLoading = false
        })
    },
    handleSubmit () {
      if (this.isRedeemCodeEdit) {
        this.updateRedeemActivity()
      } else {
        this.createRedeemActivity()
      }
    },
    createRedeemActivity () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.updateLoading = true
          const formData = this.preFormatFormData()
          createRedeemActivity({ ...formData })
            .then(res => {
              if (res.code === 200) {
                this.$message.success('新建成功')
                this.$router.back()
              }
            })
            .finally(() => {
              this.updateLoading = false
            })
        }
      })
    },
    updateRedeemActivity () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.updateLoading = true
          const formData = this.preFormatFormData()
          updateRedeemActivity({ ...formData })
            .then(res => {
              if (res.code === 200) {
                this.$message.success('更新成功')
                this.$router.back()
              }
            })
            .finally(() => {
              this.updateLoading = false
            })
        }
      })
    },
    preFormatFormData () {
      const formData = cloneDeep(this.formData)
      formData.startUsedTime = formData.dateRange[0]
      formData.endUsedTime = formData.dateRange[1]

      if (formData.limitType === 1) {
        formData.receiveCount = -1
      }

      if (formData.goodsType === 1) {
        formData.goldNum = (this.vipTypeMap[formData.goodsId] || {}).timeNumber
      } else if (formData.goodsType === 2) {
        formData.goodsId = undefined
      }

      if (this.isRedeemCodeEdit) {
        formData.oldCodeNum = this.oldCodeNum
      }
      delete formData.dateRange
      delete formData.limitType
      return formData
    },
    handleCancel () {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped lang="less">
  .content {
    margin: 50px 80px;
    h4.title {
      margin-bottom: 40px;

      &.title-2 {
        margin-top: 60px;
      }
    }

    .el-input,
    .el-textarea {
      width: 350px;
    }
    .ctrl-area{
      margin-top: 40px;
      .el-button{
        padding:10px  40px;
      }
    }
  }
</style>
